/*=========================================================================================
  File Name: modulePaymentAndWithdrawalActions.js
  Description: PaymentAndWithdrawal Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
export default {
    fetchAllPaymentAndWithdrawalList({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/PaymentAndWithdrawal/GetAllPaymentAndWithdrawals")
                .then(response => {
                    if (response.status == 200) {
                        commit("SET_PaymentAndWithdrawal", response.data);
                        resolve(response);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPaymentAndWithdrawalById(context, PaymentAndWithdrawal) {
        if (PaymentAndWithdrawal.Id == undefined) {
            PaymentAndWithdrawal.Id = "";
        }
        return new Promise((resolve, reject) => {
            axios
                .post("/PaymentAndWithdrawal/GetPaymentAndWithdrawalMethodById", PaymentAndWithdrawal)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    addPaymentAndWithdrawalItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("PaymentAndWithdrawal/AddPaymentAndWithdrawalMethod", item)
                .then(response => {
                    commit("ADD_PaymentAndWithdrawal", Object.assign(item, { ID: response.data.ID }));
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updatePaymentAndWithdrawalItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/PaymentAndWithdrawal/UpdatePaymentAndWithdrawalMethod?Id=" + item.Id, item)
                .then(response => {
                    debugger
                    commit("UPDATE_PaymentAndWithdrawal", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DeletePaymentAndWithdrawal({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/PaymentAndWithdrawal/DeletePaymentAndWithdrawalMethod", item)
                .then(response => {
                    debugger
                    commit("UPDATE_VehiclesShape", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};
