/*=========================================================================================
  File Name: modulePaymentAndWithdrawal.js
  Description: PaymentAndWithdrawal Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./modulePaymentAndWithdrawalState.js";
import mutations from "./modulePaymentAndWithdrawalMutations.js";
import actions from "./modulePaymentAndWithdrawalActions.js";
import getters from "./modulePaymentAndWithdrawalGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
