/*=========================================================================================
  File Name: modulePaymentAndWithdrawalMutations.js
  Description: PaymentAndWithdrawal Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    ADD_PaymentAndWithdrawal(state, item) {
      state.PaymentAndWithdrawal.unshift(item);
    },
  
    SET_PaymentAndWithdrawal(state, PaymentAndWithdrawal) {
      state.PaymentAndWithdrawals = PaymentAndWithdrawal;
    },
    
    UPDATE_PaymentAndWithdrawal(state, item) {
      const Index = state.PaymentAndWithdrawals.findIndex(p => p.Id == item.Id);
      Object.assign(state.PaymentAndWithdrawals[Index], item);
    }
    
  };
  