/*=========================================================================================
  File Name: modulePaymentAndWithdrawalState.js
  Description: PaymentAndWithdrawal Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    PaymentAndWithdrawal: [],
    PaymentAndWithdrawals: []
  };
  